var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"facturas-pendientes mx-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Cartera")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"footer-props":{
                  itemsPerPageText: 'Clientes',
                  itemsPerPageOptions: [10, 25, 50, -1],
                },"options":{
                  itemsPerPage: 50,
                },"loading":_vm.loading_t,"search":_vm.search,"items":_vm.sortFacturas},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-text-field',{attrs:{"outlined":"","rounded":"","dense":"","append-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{attrs:{"dark":"","color":"black","loading":_vm.loading},on:{"click":_vm.download}},[_vm._v("Descargar")])],1)],1)]},proxy:true},{key:"item.saldo",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.saldo))+" ")]}},{key:"item.detalle",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){_vm.detalle = item;
                          _vm.dialog = true;}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-question-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver detalle")])])]}},{key:"body.append",fn:function(){return [_c('tr',[_c('th',{attrs:{"colspan":"4"}},[_vm._v("Totales")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalizacion))+" ")])])]},proxy:true}])}),_c('v-dialog',{attrs:{"scrollable":"","max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.detalle)?_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.detalle.cliente)+" ")]),_c('v-card-text',[_c('v-data-table',{staticClass:"mt-2",attrs:{"headers":_vm.headers_detalle,"search":_vm.search_detalle,"dense":"","items":_vm.detalle.detalle,"footer-props":{ itemsPerPageText: 'Facturas' }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"outlined":"","rounded":"","dense":"","append-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search_detalle),callback:function ($$v) {_vm.search_detalle=$$v},expression:"search_detalle"}})],1)],1)],1)]},proxy:true},{key:"item.fecha",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.fecha).format("ll"))+" ")]}},{key:"item.vencimiento",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.vencimiento).format("ll"))+" ")]}},{key:"item.folio",fn:function(ref){
                          var item = ref.item;
return [_c('router-link',{attrs:{"to":'/detallefactura/' + item.id_factura}},[_c('span',[_vm._v(" "+_vm._s(_vm._f("folio")(item.folio))+" ")])])]}},{key:"item.total",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(parseInt(item.total)))+" ")]}}],null,false,346208407)})],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }