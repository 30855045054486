<template>
  <div class="facturas-pendientes mx-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>Cartera</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="12">
                <v-data-table
                  dense
                  :headers="headers"
                  :footer-props="{
                    itemsPerPageText: 'Clientes',
                    itemsPerPageOptions: [10, 25, 50, -1],
                  }"
                  :options="{
                    itemsPerPage: 50,
                  }"
                  :loading="loading_t"
                  :search="search"
                  :items="sortFacturas"
                >
                  <template v-slot:top>
                    <v-row dense>
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field
                          outlined
                          rounded
                          dense
                          append-icon="mdi-magnify"
                          label="Buscar"
                          v-model="search"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-btn
                          dark
                          color="black"
                          @click="download"
                          :loading="loading"
                          >Descargar</v-btn
                        >
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:item.saldo="{ item }">
                    {{ item.saldo | currency }}
                  </template>
                  <template v-slot:item.detalle="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          small
                          color="primary"
                          v-on="on"
                          v-bind="attrs"
                          @click="
                            detalle = item;
                            dialog = true;
                          "
                        >
                          <v-icon>mdi-file-question-outline </v-icon>
                        </v-btn>
                      </template>
                      <span>Ver detalle</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:body.append>
                    <tr>
                      <th colspan="4">Totales</th>
                      <th colspan="2">
                        {{ totalizacion | currency }}
                      </th>
                    </tr>
                  </template>
                </v-data-table>
                <v-dialog v-model="dialog" scrollable max-width="800px">
                  <v-card v-if="detalle">
                    <v-card-title>
                      {{ detalle.cliente }}
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                        :headers="headers_detalle"
                        :search="search_detalle"
                        dense
                        :items="detalle.detalle"
                        class="mt-2"
                        :footer-props="{ itemsPerPageText: 'Facturas' }"
                      >
                        <template v-slot:top>
                          <v-toolbar flat>
                            <v-row dense>
                              <v-col cols="4" class="px-0">
                                <v-text-field
                                  outlined
                                  rounded
                                  dense
                                  append-icon="mdi-magnify"
                                  label="Buscar"
                                  v-model="search_detalle"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-toolbar>
                        </template>
                        <template v-slot:item.fecha="{ item }">
                          {{ moment(item.fecha).format("ll") }}
                        </template>
                        <template v-slot:item.vencimiento="{ item }">
                          {{ moment(item.vencimiento).format("ll") }}
                        </template>
                        <template v-slot:item.folio="{ item }">
                          <router-link
                            :to="'/detallefactura/' + item.id_factura"
                          >
                            <span>
                              {{ item.folio | folio }}
                            </span>
                          </router-link>
                        </template>
                        <template v-slot:item.total="{ item }">
                          {{ parseInt(item.total) | currency }}
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import XLSX from "xlsx";
import * as moment from "moment";
moment.locale("es");
export default {
  data: () => ({
    loading_t: false,
    loading: false,
    search: "",
    search_detalle: "",
    dialog: false,
    detalle: null,
    facturas: [],
    centro_id: null,
    headers_detalle: [
      {
        text: "Fecha factura",
        value: "fecha",
        width: 110,
      },
      {
        text: "Vencimiento",
        value: "vencimiento",
        width: 110,
      },
      {
        text: "N° factura",
        value: "folio",
        width: 100,
      },
      {
        text: "Total",
        value: "total",
        width: 100,
      },
    ],
  }),
  methods: {
    loadFacturas() {
      this.loading_t = true;
      const body = {
        route: "/facturasPendientes",
      };
      this.$store
        .dispatch("axios_get", body)
        .then((response) => {
          if (response.data.success) {
            this.facturas = response.data.data;
            if (this.facturas.length > 0) {
              this.centro_id = this.facturas[0].centro_id;
            }
          }
        })
        .finally(() => (this.loading_t = false));
    },
    download() {
      this.loading = true;
      const data = this.sortFacturas.map((cliente) => {
        let Facturas = cliente.detalle.map((f) => {
          return this.$options.filters.folio(f.folio);
        });

        Facturas = Facturas.toString();

        Facturas = Facturas.replace(/,/g, " | ");

        let return_data =  {
          "Cc/ID": cliente.cc_cliente,
          Cliente: cliente.cliente,
          Telefono: cliente.telefono,
          Facturas,
          "Cant Fac": cliente.cantidad,
          Saldo: cliente.saldo,
          Direccion: cliente.direccion,
        };

        if(this.centro_id === 260){
          return_data["Zona"] = cliente.zona
        }

        return return_data
      });

      const filename = "Facturas por cobrar";
      let excel = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, excel, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
      this.loading = false;
    },
    moment(data) {
      return moment(data);
    },
  },
  created() {
    this.loadFacturas();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    headers() {
      const header_default = [
        {
          align: "start",
          text: "CC / ID",
          value: "cc_cliente",
        },
        {
          text: "Cliente",
          value: "cliente",
        },
        {
          text: "Dirección",
          value: "direccion",
        },
        {
          text: "Cantidad",
          value: "cantidad",
        },
        {
          text: "Saldo",
          value: "saldo",
          sortable: false,
          filterable: false,
        },
        {
          text: "Detalle",
          value: "detalle",
          sortable: false,
          filterable: false,
        },
      ];

      const header_update = [
        {
          align: "start",
          text: "CC / ID",
          value: "cc_cliente",
        },
        {
          text: "Cliente",
          value: "cliente",
        },
        {
          text: "Dirección",
          value: "direccion",
          width: 180,
          filterable: false,
        },
        {
          text: "Zona",
          value: "zona",
        },
        {
          text: "Cantidad",
          value: "cantidad",
        },
        {
          text: "Saldo",
          value: "saldo",
          sortable: false,
          filterable: false,
        },
        {
          text: "Detalle",
          value: "detalle",
          sortable: false,
          filterable: false,
        },
      ];

      let header = [];
      if (this.centro_id && this.centro_id === 260) {
        header = header_update;
      } else {
        header = header_default;
      }

      return header;
    },
    lista() {
      let facturas = this.facturas.map((f) => {
        let data = f;

        if (this.centro_id && this.centro_id === 260) {
          function zonaSelected(direccion) {
            let zona = null;
            let extract = null;
            let d_corta = direccion;

            if (direccion.includes(" [")) {
              extract = direccion.split(" [");
            }

            if (extract && extract.length === 2) {
              d_corta = extract[0];

              let sub_extract = extract[1].split(", ");

              if (sub_extract && sub_extract.length === 2) {
                zona = sub_extract[1].split("]", sub_extract[1].length - 1)[0];
              }
            }

            return { zona, direccion: d_corta };
          }

          let ubicacion = zonaSelected(data.direccion);

          data.direccion = ubicacion.direccion;

          data["zona"] = ubicacion.zona;
        }
        return data;
      });

      return facturas;
    },
    sortFacturas() {
      return this.lista.sort((a, b) => {
        return b.cantidad - a.cantidad;
      });
    },
    totalizacion() {
      return this.facturas.reduce((index, item) => {
        return index + parseFloat(item.saldo);
      }, 0);
    },
    totalizacionDetalle() {
      if (this.detalle)
        return this.detalle.detalle.reduce((index, item) => {
          return index + parseFloat(item.total);
        }, 0);
      else return [];
    },
  },
};
</script>
